import { Business } from 'models/entities/business';

type Data = {
  target: Business;
};

type DataToApply = {
  target?: Business;
};

class Model {

  readonly target: Business;
  readonly hasMenuCategories: boolean;

  constructor(data: Data) {
    this.target = data.target;
    this.hasMenuCategories = !!this.target.menuCategories.documents.length;
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const hasMenuCategories = !!props.target.menuCategories.documents.length;
    return Object.assign(Object.create(this.constructor.prototype), { ...props, hasMenuCategories });
  }

}

export { Model };