import { Business, BusinessCollection } from 'models/entities/business';
import { BusinessCategoryCollection } from 'models/entities/business-category';
import { PosSystemCollection } from 'models/entities/pos-system';
import { OriginalIngredientCategoryCollection } from 'models/entities/original-ingredient-category';
import { OriginalMenuCategoryCollection } from 'models/entities/original-menu-category';

import { Condition } from './models/condition';
import { ReadGql } from './models/read-gql';

class Model {

  readonly condition: Condition;
  readonly collection?: BusinessCollection;
  readonly list?: Business[];
  readonly categories?: BusinessCategoryCollection;
  readonly posSystems?: PosSystemCollection;
  readonly ingredientCategories?: OriginalIngredientCategoryCollection;
  readonly menuCategories?: OriginalMenuCategoryCollection;

  constructor() {
    this.condition = new Condition();
  }

  async read(): Promise<this> {
    const gql = await new ReadGql().fetch();
    if (!gql.result) throw new Error('invalid result');
    const collection = new BusinessCollection(gql.result.collection);
    const list = this.condition.filter(collection.documents);
    const categories = new BusinessCategoryCollection(gql.result.categories);
    const posSystems = new PosSystemCollection(gql.result.posSystems);
    const ingredientCategories = new OriginalIngredientCategoryCollection(gql.result.ingredientCategories);
    const menuCategories = new OriginalMenuCategoryCollection(gql.result.menuCategories);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list, categories, posSystems, ingredientCategories, menuCategories });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  async readAllCategories(): Promise<this> {
    if (!this.categories) throw new Error('categories is undefined');
    const categories = await this.categories.readAll();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, categories });
  }

  async readAllPosSystems(): Promise<this> {
    if (!this.posSystems) throw new Error('posSystems is undefined');
    const posSystems = await this.posSystems.readAll();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, posSystems });
  }

  build(): Business {
    if (!this.collection) throw new Error('collection is undefined');
    if (!this.categories) throw new Error('categories is undefined');
    if (!this.posSystems) throw new Error('posSystems is undefined');
    return Business.buildNext(this.collection.documents, { category: this.categories.documents[0], posSystem: this.posSystems.documents[0] });
  }

  add(business: Business): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.add(business));
  }

  replace(business: Business): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.replace(business));
  }

  remove(business: Business): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.remove(business));
  }

  private apply(collection: BusinessCollection): this {
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

}

export { Model };