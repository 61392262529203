import React, { useState, useEffect } from 'react';

import { Business } from 'models/entities/business';
import { ActionName } from './models/action-name';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { useModal, ModalResult } from 'views/services/modal';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { Header } from 'views/components/header';
import { ActionsButton } from './views/actions-button';
import { Root, Frame, List } from './index.styled';

const Businesses = () => {

  const auth = useAuth();
  const modal = useModal();
  const [model, setModel] = useState(new Model());

  function edit(business?: Business) {
    const target = business ?? model.build();
    const tell = apply;
    modal.open('edit-business', { target, strict: true, other: { categories: model.categories, posSystems: model.posSystems }, tell });
  }

  function selectAction(action: ActionName, target: Business) {
    switch (action) {
      case 'edit-ingredient-categories': return modal.open('edit-ingredient-categories', { target, strict: true, other: { categories: model.ingredientCategories }, tell: apply });
      case 'edit-menu-categories': return modal.open('edit-menu-categories', { target, strict: true, other: { categories: model.menuCategories }, tell: apply });
      case 'edit-dish-categories': return modal.open('edit-dish-categories', { target, strict: true, tell: apply });
    }
  }

  function apply(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof Business)) return;
    const business = result.target as Business;
    switch (result.state) {
      case 'created': setModel(model.add(business)); break;
      case 'updated': setModel(model.replace(business)); break;
      case 'deleted': setModel(model.remove(business)); break;
    }
  }

  function watchAuth() {
    if (!auth.ok) return;
    model.read().then(model => setModel(model));
  }

  function watchCollection() {
    if (!model.collection) return;
    if (!model.collection.nextToken) return;
    model.readAllCollection().then(it => setModel(it));
  }

  function watchCategories() {
    if (!model.categories) return;
    if (!model.categories.nextToken) return;
    model.readAllCategories().then(it => setModel(it));
  }

  function watchPosSystems() {
    if (!model.posSystems) return;
    if (!model.posSystems.nextToken) return;
    model.readAllPosSystems().then(it => setModel(it));
  }

  useEffect(watchAuth, [auth.ok]);
  useEffect(watchCollection, [model.collection]);
  useEffect(watchCategories, [model.categories]);
  useEffect(watchPosSystems, [model.posSystems]);

  return (
    <Root>
      <Header mode={auth.user.admin ? 'admin' : ''} onCreate={() => edit()} />
      {!model.collection && <InterfaceLoading />}
      {model.list && (
        <Frame>
          {!model.list.length ? <NoInterfaceData>No Business</NoInterfaceData> : (
            <List>
              <table>
                <thead>
                <tr>
                  <th>Category</th>
                  <th>Name</th>
                  <th>Location</th>
                  <th>Code</th>
                  <th>Currency</th>
                  <th>Closed</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {model.list.map((it, i) => (
                  <tr key={i}>
                    <td onClick={() => edit(it)}>{it.category.name}</td>
                    <td onClick={() => edit(it)}>{it.name}</td>
                    <td onClick={() => edit(it)}>{it.location}</td>
                    <td onClick={() => edit(it)}>{it.code}</td>
                    <td onClick={() => edit(it)}>{it.currency.code}</td>
                    <td onClick={() => edit(it)}>{it.closed ? 'closed' : '---'}</td>
                    <td className="actions">
                      <div>
                        <ActionsButton target={it} onSelect={action => selectAction(action, it)} />
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </List>
          )}
        </Frame>
      )}
    </Root>
  );

};

export default Businesses;