import { Api } from 'models/services/api';
import { ReadBusinessesQuery, ReadBusinessCategoriesQuery, ReadPosSystemsQuery, ReadOriginalIngredientCategoriesQuery, ReadOriginalMenuCategoriesQuery } from 'models/services/graphql';
import { BusinessCollectionData } from 'models/entities/business';
import { BusinessCategoryCollectionData } from 'models/entities/business-category';
import { PosSystemCollectionData } from 'models/entities/pos-system';
import { OriginalIngredientCategoryCollectionData } from 'models/entities/original-ingredient-category';
import { OriginalMenuCategoryCollectionData } from 'models/entities/original-menu-category';

const RequestQuery = `
  query Businesses($nextToken: String) {
    ${ReadBusinessesQuery}
    ${ReadBusinessCategoriesQuery}
    ${ReadPosSystemsQuery}
    ${ReadOriginalIngredientCategoriesQuery}
    ${ReadOriginalMenuCategoriesQuery}
  }
`;

type ResponseData = {
  data: {
    readBusinesses: BusinessCollectionData;
    readBusinessCategories: BusinessCategoryCollectionData;
    readPosSystems: PosSystemCollectionData;
    readOriginalIngredientCategories: OriginalIngredientCategoryCollectionData;
    readOriginalMenuCategories: OriginalMenuCategoryCollectionData;
  }
}

type Result = {
  collection: BusinessCollectionData;
  categories: BusinessCategoryCollectionData;
  posSystems: PosSystemCollectionData;
  ingredientCategories: OriginalIngredientCategoryCollectionData;
  menuCategories: OriginalMenuCategoryCollectionData;
}

class ReadGql {

  readonly result?: Result;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(): Promise<this> {
    const api = await this.api.fetch();
    if (!api.response) throw new Error('invalid response');
    const collection = api.response.data.readBusinesses;
    const categories = api.response.data.readBusinessCategories;
    const posSystems = api.response.data.readPosSystems;
    const ingredientCategories = api.response.data.readOriginalIngredientCategories;
    const menuCategories = api.response.data.readOriginalMenuCategories;
    const result = { collection, categories, posSystems, ingredientCategories, menuCategories };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadGql };